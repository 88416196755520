import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { axisClasses, BarChart, PieChart } from "@mui/x-charts";
import { TextField, Chip, Autocomplete, Grid, Box } from "@mui/material";
import { Typography } from "@mui/material";
import { Card } from "@mui/material";
import { showToast } from "../../Components/ToastBox";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { Toolbar } from "@mui/material";
import AppText from "../../Utils/AppText";
import { Button } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import DataServices from "../../Services/DataServices";
import BackDropLoading from "../../Components/Loading";
import moment from "moment";
import { processAttendanceData } from "../../Utils/CommonFunctions";

const TimeSheetAndChart = () => {
  const [timesheetData, setTimesheetData] = useState([]);
  const [chartTimesheetData, setchartTimesheetData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [startDate, setStartDate] = useState(null); // Start date
  const [endDate, setEndDate] = useState(null); // End date
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);

  const timeThreshold = "10:00:00";
  useEffect(() => {
    fetchOrgDropdownData();
  }, []);

  const fetchOrgDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await DataServices.getOrgDropDownDetailsApi();
      if (response.statusCode == 200) {
        try {
          var dummy = [...response.response.body];
          if (orgDropDownOptions < 1) {
            setOrgDropDownOptions(dummy);
            const defaultOption = dummy?.find(
              (option) => option?.value === "202501271"
            );
            setSelectedOrganization(defaultOption || dummy[0]);
          }
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async (initialPage, rowsPerPage, startDate, endDate) => {
    try {
      setIsLoading(true);
      const queryParams = {
        orgCode: selectedOrganization.value,
        pageNo: initialPage,
        pageSize: rowsPerPage,
        type: "timesheet",
        fromDate: startDate
          ? startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "",
        toDate: endDate
          ? endDate.endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "",
      };

      const response = await DataServices.getAttendanceTracking(queryParams);

      if (response?.statusCode === "200" && response?.response?.body) {
        const unsortedData = response.response.body;
        setTimesheetData(unsortedData?.content);
        setTotalRows(unsortedData?.totalElements);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading stops
    }
  };

  const fetchDataChart = async () => {
    try {
      setIsLoading(true);

      const today = moment().format("YYYY-MM-DD 00:00:00"); // Current date
      const oneWeekAgo = moment()
        .subtract(7, "days")
        .format("YYYY-MM-DD 00:00:00"); // Date 7 days ago
      const queryParams = {
        orgCode: selectedOrganization?.value,
        pageNo: 0,
        pageSize: 1000,
        type: "timesheet",
        fromDate: oneWeekAgo,
        toDate: today,
      };

      const response = await DataServices.getAttendanceTracking(queryParams);

      if (response?.statusCode === "200" && response?.response?.body) {
        const unsortedData = response?.response?.body;

        const dataset = processAttendanceData(
          unsortedData.content,
          timeThreshold
        );

        setchartTimesheetData(dataset);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading stops
    }
  };

  useEffect(() => {
    // Check if organization is selected and both dates are either null or both are set
    if (
      selectedOrganization?.value?.length > 0 &&
      (!startDate || (startDate && endDate)) // Either startDate is not set, or both startDate and endDate are set
    ) {
      fetchData(initialPage, rowsPerPage, startDate, endDate);
    }
  }, [startDate, endDate, selectedOrganization]);

  useEffect(() => {
    // Check if organization is selected and both dates are either null or both are set
    if (selectedOrganization?.value?.length > 0) {
      fetchDataChart();
    }
  }, [selectedOrganization]);

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    fetchData(initialPage, newRowsPerPage, startDate, endDate);
  };
  const rowsPerPageOptions = [10, 25, 50, 100];
  const options = {
    responsive: "standard",
    filter: false,
    selectableRows: "none",
    search: false,
    serverSide: true,
    count: totalRows,
    download: false,
    print: false,
    viewColumns: false,
    pagination: true,
    page: initialPage,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setInitialPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setInitialPage(0);
          break;

        default:
      }
    },

    onChangeRowsPerPage: handleRowsPerPageChange,
  };

  // const dataset = [
  //   { id: 1, day: "Mon", OnTime: 15, Late: 5 },
  //   { id: 2, day: "Tue", OnTime: 20, Late: 4 },
  //   { id: 3, day: "Wed", OnTime: 22, Late: 2 },
  //   { id: 4, day: "Thu", OnTime: 18, Late: 6 },
  //   { id: 5, day: "Fri", OnTime: 25, Late: 5 },
  //   { id: 6, day: "Sat", OnTime: 16, Late: 8 },
  //   { id: 7, day: "Sun", OnTime: 18, Late: 6 },
  // ];

  const evaluateCheckIn = (checkInTime) => {
    const checkInDate = new Date(checkInTime);

    // Define 10:00 AM as the threshold for being "On Time"
    const lateThreshold = new Date(checkInDate);
    lateThreshold.setHours(10, 0, 0, 0); // Set time to 10:00:00

    // Compare check-in time with the threshold
    return checkInDate >= lateThreshold ? "Late" : "On Time";
  };

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: true,

        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>{initialPage * rowsPerPage + tableMeta.rowIndex + 1}</span>
          );
        },
      },
    },
    { name: "name", label: "Name", options: { sort: true } },

    {
      name: "checkInTime",
      label: "Clock In",
      options: {
        sort: true,
        customBodyRender: (value) =>
          moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        sort: true,
        // customBodyRenderLite: (value, dataIndex, updateValue) => {
        //   const checkInTime = timesheetData[dataIndex]?.checkInTime;
        //   return evaluateCheckIn(checkInTime);
        // },
      },
    },
  ];

  const bardata = [
    {
      data: [200, 400, 600, 500],
    },
  ];

  const chartSetting = {
    yAxis: [
      {
        label: "Count", // Label for the y-axis
      },
    ],
    width: 800, // Set the chart width
    height: 400, // Set the chart height
  };

  const today = moment().format("D MMM, YYYY");
  const sevenDaysAgo = moment().subtract(7, "days").format("D MMM, YYYY");
  return (
    <Box display="flex" minHeight="100vh">
      <Grid container spacing={2} direction="row">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            sx={{ width: 250 }}
            options={orgDropDownOptions}
            value={selectedOrganization}
            onChange={(event, newValue) => setSelectedOrganization(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Card sx={{ width: "90%" }}>
            <Typography variant="h6" m={2}>
              {sevenDaysAgo} - {today}
            </Typography>
            {chartTimesheetData.length > 0 ? (
              <BarChart
                dataset={chartTimesheetData}
                xAxis={[{ scaleType: "band", dataKey: "day" }]}
                series={[
                  { dataKey: "OnTime", label: "On Time" },
                  { dataKey: "Late", label: "Late" },
                ]}
                {...chartSetting}
              />
            ) : (
              <Typography textAlign={"center"}>
                No data available to display
              </Typography>
            )}
          </Card>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          marginTop={2}
        >
          <Grid item xs={12} md={3}>
            <Typography variant="h5">{AppText.attendance.Timesheet}</Typography>
          </Grid>

          <Grid
            container
            item
            xs={12}
            md={6}
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  value={startDate}
                  disableFuture
                  onChange={(newValue) => setStartDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="End Date"
                  value={endDate}
                  disableFuture
                  minDate={startDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button
                variant="outlined"
                size="large"
                sx={{ width: "100%" }}
                startIcon={<CloudDownloadOutlinedIcon />}
              >
                {AppText.buttonText.export}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            title={<div> </div>}
            data={timesheetData}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>

      {isLoading && <BackDropLoading open={isLoading} />}
    </Box>
  );
};

export default TimeSheetAndChart;
