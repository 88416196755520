import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { TextField, Chip, Autocomplete, Grid, Box } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useNavigate } from "react-router-dom";
import { showToast } from "../../Components/ToastBox";
import { Toolbar } from "@mui/material";
import { Typography } from "@mui/material";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import AppText from "../../Utils/AppText";
import { Button } from "@mui/material";
import DataServices from "../../Services/DataServices";
import BackDropLoading from "../../Components/Loading";
import moment from "moment";

const AllRecords = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [startDate, setStartDate] = useState(null); // Start date
  const [endDate, setEndDate] = useState(null); // End date
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <span>{initialPage * rowsPerPage + tableMeta.rowIndex + 1}</span>
          );
        },
      },
    },
    { name: "name", label: "Name", options: { sort: true } },

    {
      name: "checkInTime",
      label: "Time",
      options: {
        sort: true,
        customBodyRender: (value) =>
          moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
    { name: "location", label: "Status", options: { sort: true } },
  ];

  useEffect(() => {
    fetchOrgDropdownData();
  }, []);

  const fetchOrgDropdownData = async () => {
    try {
      setIsLoading(true);
      const response = await DataServices.getOrgDropDownDetailsApi();
      if (response.statusCode == 200) {
        try {
          var dummy = [...response.response.body];
          if (orgDropDownOptions < 1) {
            setOrgDropDownOptions(dummy);

            const defaultOption = dummy?.find(
              (option) => option?.value === "202501271"
            );
            setSelectedOrganization(defaultOption || dummy[0]); // Fallback to the first option if not found
          }
        } catch (error) {
          showToast("Something went wrong ", "error");
        }
      } else {
        showToast(response.statusDescription, "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    fetchData(initialPage, newRowsPerPage, startDate, endDate);
  };

  const fetchData = async (initialPage, rowsPerPage, startDate, endDate) => {
    try {
      setIsLoading(true);
      const queryParams = {
        orgCode: selectedOrganization.value,
        pageNo: initialPage,
        pageSize: rowsPerPage,

        fromDate: startDate
          ? startDate.startOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "",
        toDate: endDate
          ? endDate.endOf("day").format("YYYY-MM-DD HH:mm:ss")
          : "",
      };

      const response = await DataServices.getAttendanceTracking(queryParams);

      if (response?.statusCode === "200" && response?.response?.body) {
        const unsortedData = response.response.body;
        setData(unsortedData.content);
        setTotalRows(unsortedData.totalElements);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading stops
    }
  };

  useEffect(() => {
    if (
      selectedOrganization?.value?.length > 0 &&
      (!startDate || (startDate && endDate))
    ) {
      fetchData(initialPage, rowsPerPage, startDate, endDate);
    }
  }, [startDate, endDate, selectedOrganization]);

  const rowsPerPageOptions = [10, 25, 50, 100];
  const options = {
    responsive: "standard",
    filter: false,
    selectableRows: "none",
    search: false,
    serverSide: true,
    count: totalRows,
    download: false,
    print: false,
    viewColumns: false,
    pagination: true,
    page: initialPage,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setInitialPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setInitialPage(0);
          break;

        default:
      }
    },

    onChangeRowsPerPage: handleRowsPerPageChange,
  };

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12}>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">{AppText.attendance.AllRecords}</Typography>
        </Toolbar>
      </Grid>
      <Grid item xs={12} md={3}>
        <Autocomplete
          disablePortal
          id="OrganizationName"
          options={orgDropDownOptions}
          value={selectedOrganization}
          onChange={(event, newValue) => setSelectedOrganization(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Organization Name" />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Start Date"
            value={startDate}
            disableFuture
            onChange={(newValue) => setStartDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="End Date"
            value={endDate}
            disableFuture
            minDate={startDate}
            onChange={(newValue) => setEndDate(newValue)}
            renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={3}>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CloudDownloadOutlinedIcon />}
        >
          {" "}
          {AppText.buttonText.export}
        </Button>
      </Grid> */}

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        marginTop={2}
      >
        <Grid item xs={12} md={3}>
          <Typography variant="h5">{AppText.attendance.AllRecords}</Typography>
        </Grid>

        <Grid
          container
          item
          xs={12}
          md={9}
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item xs={12} md={3}>
            <Autocomplete
              disablePortal
              id="OrganizationName"
              options={orgDropDownOptions}
              value={selectedOrganization}
              onChange={(event, newValue) => setSelectedOrganization(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Organization Name" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                disableFuture
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                disableFuture
                minDate={startDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <Button
              variant="outlined"
              size="large"
              sx={{ width: "100%" }}
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              {AppText.buttonText.export}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <MUIDataTable
          title={AppText.attendance.Timesheet}
          data={data}
          columns={columns}
          options={options}
        />
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default AllRecords;
