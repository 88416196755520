import request from "./http-common";

const loginApi = async (data) => {
  return await request.postApi(data, `/dashboard/login`);
};

const uwbTracking = async (data) => {
  return await request.getAPi(`ble/tracker?orgCode=${data}`);
};
const meetingRoomBookingHistory = async (data) => {
  return await request.getAPi(
    `bookings/history?pageNo=${data?.pageNo}&pageSize=${data?.pageSize}&orgCode=${data?.orgCode}`
  );
};
// organizations
const addNewOrganizationApi = async (data) => {
  return await request.postApi(data, `organizations`);
};

const editOrganizationApi = async (data) => {
  return await request.putApi(data, `organizations`);
};

const getOrganizationsDetilsApi = async (data) => {
  return await request.getAPi(`organizations`);
};

const getOrgDropDownDetailsApi = async (data) => {
  return await request.getAPi(`organizations/dropdown`);
};
//users
const addNewUserApi = async (data) => {
  return await request.postApi(data, `users`);
};

const editUserApi = async (data) => {
  return await request.putApi(data, `users`);
};

const getUsersDetilsApi = async (data) => {
  return await request.getAPi(`users?searchText=${data}`);
};
const deleteUserApi = (data) => {
  return request.deleteApi(`users/${data}`);
};
//Projects
const addNewProjectsApi = async (data) => {
  return await request.postApi(data, `projects`);
};

const editProjectsApi = async (data) => {
  return await request.putApi(data, `projects`);
};

const getProjectsDetilsApi = async (data) => {
  return await request.getAPi(`projects`);
};
const deleteProjectsApi = (data) => {
  return request.deleteApi(`projects/${data}`);
};

const getWorkstationHistoryApi = async (data) => {
  return await request.getAPi(`hotdesk/history?searchText=${data}`);
};

const getWorkstationAndMeetingRoomSetupApi = async (data) => {
  return await request.getAPi(`meeting/rooms?searchText=${data}`);
};
const getAttendanceTracking = async (data) => {
  return await request.getAPi(
    `ble/tracker/${data?.orgCode}?pageNo=${data?.pageNo}&pageSize=${
      data?.pageSize
    }&fromDate=${data?.fromDate ?? ""}&toDate=${data?.toDate ?? ""}&type=${
      data?.type ?? ""
    }`
  );
};

export default {
  loginApi,
  uwbTracking,
  meetingRoomBookingHistory,
  addNewOrganizationApi,
  editOrganizationApi,
  getOrganizationsDetilsApi,
  getOrgDropDownDetailsApi,
  addNewUserApi,
  editUserApi,
  getUsersDetilsApi,
  deleteUserApi,
  addNewProjectsApi,
  editProjectsApi,
  getProjectsDetilsApi,
  deleteProjectsApi,
  getWorkstationHistoryApi,
  getWorkstationAndMeetingRoomSetupApi,
  getAttendanceTracking,
};
