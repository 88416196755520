import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Typography,
  Stack,
  TextField,
  Autocomplete,
} from "@mui/material";
import moment from "moment";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import MUIDataTable from "mui-datatables";

import AppText from "../../Utils/AppText";
import DataServices from "../../Services/DataServices";
import BackDropLoading from "../../Components/Loading";
import { showToast } from "../../Components/ToastBox";

const UwbTrackingPage = () => {
  const [data, setData] = useState();

  const [unsortedData, setUnsortedData] = useState([]);
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [timeLineData, setTimeLineData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    // Check if organization is selected and both dates are either null or both are set
    if (selectedOrganization?.value?.length > 0) {
      fetchData(initialPage, rowsPerPage);
    }

    const fetchInterval = setInterval(() => {
      console.log("entering");

      fetchData(initialPage, rowsPerPage);
    }, 3 * 60 * 1000);

    return () => clearInterval(fetchInterval);
  }, [selectedOrganization]);

  const fetchData = async (initialPage, rowsPerPage) => {
    try {
      setIsLoading(true);
      const queryParams = {
        orgCode: selectedOrganization.value,
        pageNo: initialPage,
        pageSize: rowsPerPage,
      };

      const response = await DataServices.getAttendanceTracking(queryParams);

      if (response?.statusCode === "200" && response?.response?.body) {
        const unsortedData = response?.response?.body?.content;
        setUnsortedData(unsortedData);
        const processedData = filterLatestEntries(unsortedData);
        setData(processedData);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading stops
    }
  };

  useEffect(() => {
    const fetchOrgDropdownData = async () => {
      try {
        setIsLoading(true);
        const response = await DataServices.getOrgDropDownDetailsApi();
        if (response.statusCode == 200) {
          try {
            var dummy = [...response.response.body];
            if (orgDropDownOptions < 1) {
              setOrgDropDownOptions(dummy);
              const defaultOption = dummy?.find(
                (option) => option?.value === "202501271"
              );
              setSelectedOrganization(defaultOption || dummy[0]);
            }
          } catch (error) {
            showToast("Something went wrong ", "error");
          }
        } else {
          showToast(response.statusDescription, "error");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrgDropdownData();
  }, []);

  const columns = [
    {
      name: "email",
      label: "Name",
      options: {
        sort: true,
      },
    },
    {
      name: "checkInTime",
      label: "Last Seen",
      options: {
        sort: true,
        customBodyRender: (value) =>
          moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss"),
      },
    },
    {
      name: "location",
      label: "Location",
      options: {
        sort: true,
      },
    },
  ];

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    fetchData(initialPage, newRowsPerPage);
  };
  const rowsPerPageOptions = [10, 25, 50, 100];
  const options = {
    onRowClick: (rowData) => handleRowClick(rowData[0]),
    responsive: "standard",
    filter: false,
    selectableRows: "none",
    search: false,
    serverSide: true,
    count: totalRows,
    download: false,
    print: false,
    viewColumns: false,
    pagination: true,
    page: initialPage,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setInitialPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setInitialPage(0);
          break;

        default:
      }
    },

    onChangeRowsPerPage: handleRowsPerPageChange,
  };

  const filterLatestEntries = (entries) => {
    const latestEntriesMap = new Map();

    entries.forEach((entry) => {
      const currentCheckInUTC = moment.utc(
        entry.checkInTime,
        "YYYY-MM-DD HH:mm:ss"
      );
      const existingEntry = latestEntriesMap.get(entry.email);

      if (
        !existingEntry ||
        currentCheckInUTC.isAfter(
          moment.utc(existingEntry.checkInTime, "YYYY-MM-DD HH:mm:ss")
        )
      ) {
        latestEntriesMap.set(entry.email, entry);
      }
    });

    return Array.from(latestEntriesMap.values());
  };

  const handleRowClick = (email) => {
    // Filter the timeline data for the selected email
    const filteredData = unsortedData?.filter(
      (uwbData) => uwbData?.email === email
    );
    setTimeLineData(filteredData);
  };

  return (
    <Grid container columnSpacing={3}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h5" style={{ marginBottom: "20px" }}>
          {AppText.heading.uwbtracking}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          disablePortal
          id="OrganizationName"
          options={orgDropDownOptions}
          sx={{ width: 250, float: "left", marginBottom: "20px" }}
          value={selectedOrganization}
          onChange={(event, newValue) => setSelectedOrganization(newValue)}
          renderInput={(params) => (
            <TextField {...params} label="Organization Name" />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6} sm={12}>
        {/* MUIDataTable */}
        <MUIDataTable data={data} columns={columns} options={options} />
      </Grid>
      {/* <Grid item xs={12} sm={12} md={4} style={{ height: '100vh',display:"flex", justifyContent:"center", alignItems:"center"}} >
      <MapInteractionCSS showControls defaultValue={{
        scale: 1,
        translation: { x: 0, y: 0 }
      }}
      minScale={0.5}
      maxScale={3}
      translationBounds={{
        xMax: 400,
        yMax: 200
      }}>
        <img src={layout} style={{ width: '100%', height: '100%'}} alt="layout" />
      </MapInteractionCSS>
      </Grid> */}
      <Grid item xs={12} sm={12} md={6}>
        <Card
          variant="outlined"
          sx={{
            p: 2,
            width: { xs: "100%", md: "100%", sm: "auto" },
            display: "flex",
            flexDirection: { xs: "column", md: "row", sm: "row" },
            alignItems: "center",
            marginTop: { xs: 2, md: 0, sm: 2 },
          }}
        >
          <Typography variant="h6">{AppText.timeLine}</Typography>
        </Card>

        <Timeline
          sx={{
            "& .MuiTimelineItem-root:before": {
              flex: 0,
              padding: 0,
            },
            "& .MuiTimeline-positionRight:before": {
              padding: 0,
            },
          }}
        >
          {timeLineData?.length > 0 ? (
            timeLineData?.map((uwbData, index) => (
              <TimelineItem key={index} style={{ margin: 0, padding: 0 }}>
                <TimelineSeparator color="primary">
                  <TimelineDot color="primary" />
                  {index < timeLineData.length - 1 && (
                    <TimelineConnector color="primary" />
                  )}
                </TimelineSeparator>
                <TimelineContent>
                  <Card
                    variant="outlined"
                    sx={{
                      p: 2,
                      width: { xs: "100%", md: "100%", sm: "auto" },
                      display: "flex",
                      flexDirection: { xs: "column", md: "row", sm: "row" },
                      alignItems: "center",
                    }}
                  >
                    <Grid container>
                      <Grid
                        item
                        sm={5}
                        xs={5}
                        md={5}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ fontWeight: "bold" }}
                        >
                          Area
                        </Typography>
                        <Typography variant="caption">
                          {uwbData.location}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={5}
                        xs={5}
                        md={5}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
                        >
                          Time Range
                        </Typography>
                        <Typography variant="body2">
                          {/* {`${moment
                            .utc(uwbData.entryTime)
                            .local()
                            .format("hh:mm A")} - ${moment
                            .utc(uwbData.exitTime)
                            .local()
                            .format("hh:mm A")}`} */}
                          {`${moment
                            .utc(uwbData?.checkInTime)
                            .local()
                            .format("hh:mm A")}`}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sm={2}
                        xs={2}
                        md={2}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Typography
                          variant="caption"
                          sx={{ alignSelf: "flex-start", fontWeight: "bold" }}
                        >
                          Duration
                        </Typography>
                        <Typography variant="caption">
                          {uwbData.duration}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Card>
                </TimelineContent>
              </TimelineItem>
            ))
          ) : (
            <Typography variant="body1">No data found</Typography>
          )}
        </Timeline>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};
export default UwbTrackingPage;
