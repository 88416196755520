const AppText = {
  chopeAi: `CHOPE.AI`,
  role: `Role`,
  Privileges: `Privileges`,
  Cancel: `Cancel`,
  SaveChanges: `Save Changes`,
  overview: `Overview`,
  timeLine: `Time Line`,
  emptyLoginfields: `Please fill Email or Password`,

  confirmDeletion: `Confirm Deletion`,
  deleteDialog: `Are you sure you want to delete?`,
  cancel: `Cancel`,
  yes: `Yes`,
  superAdminProjects: {
    subTitle1: `Project info`,
    subText1: `Update your project details`,
    subTitle2: `Project info`,
    subText2: `Update your project details`,
    subTitle3: `Project Configuration`,
    subText3: `Update project configuration details`,
  },
  superAdminUsers: {
    subText2: `These are notifications for comments on your posts and replies to your comments.`,
    subText3: `Add special privileges here`,
    subTitle1: `Personal info`,
    subText1: `Update your Personal details`,
    subTitle2: `Profile`,
    subText4: `Update your portfolio and bio.`,
  },
  subscription: {
    buyMoreLicenses: `Buy more licenses`,
    licenseAssignment: `License Assignment`,
  },
  heading: {
    users: `Users`,
    profile: `Profile`,
    createProject: `Create Project`,
    subscription: `Subscription`,
    ProjectOverview: `Project Overview`,
    uwbtracking: `RTLS`,
  },
  buttonText: {
    addUser: ` Add User`,
    viewAlUsers: `View all users`,
    customize: `Customize`,
    export: `Export`,
    addNewProject: `Add New Project`,
  },
  fields: {
    userName: `User Name`,
    firstName: `First name`,
    lastName: `Last name`,
    email: `Email`,
    emailAddress: `Email Address`,
    mobileNo: `Mobile Number`,
    country: `Country`,
    organization: `Organization`,
    website: `Website`,
    description: `Description`,
    timeZone: `TimeZone`,
    product: "Product",
    mobilePhone: `Mobile phone`,
    OfficePhone: `Office phone`,
    jobTitle: `Job title`,
    contactPerson: `Contact person`,
    zipcode: `Zipcode`,
    address: `Address`,
    projectName: `Project Name `,
    projectConfigurator: `Project  configurator (?)`,
    projectID: `Project ID`,
  },
  mRCmTxt: {
    meetingRoomBookinHistory: `Meeting Room Booking History`,
  },
  attendance :{
    Timesheet:`Time Sheet`,
    AllRecords:`All Records`
  }
};
export default AppText;
