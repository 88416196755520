async function sha256(message) {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}
export { sha256 };

// export const processAttendanceData = (rawData, timeThreshold) => {
//   if (!Array.isArray(rawData) || rawData.length === 0) {
//     console.error("Invalid or empty rawData:", rawData);
//     return [];
//   }

//   console.log("Processing rawData:", rawData);

//   // Initialize dataset with all days set to 0
//   const weekdayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
//   const dataset = weekdayOrder.reduce((acc, day) => {
//     acc[day] = { OnTime: 0, Late: 0 };
//     return acc;
//   }, {});

//   rawData.forEach((entry) => {
//     if (!entry || !entry.checkInTime) {
//       console.warn("Skipping invalid entry:", entry);
//       return;
//     }

//     try {
//       const date = new Date(entry.checkInTime);
//       if (isNaN(date)) {
//         console.warn("Invalid date:", entry.checkInTime);
//         return;
//       }

//       const day = date.toLocaleString("en-US", { weekday: "short" }); // e.g., "Mon", "Tue"
//       const isOnTime =
//         date.toLocaleTimeString("en-US", { hour12: false }) < timeThreshold;

//       if (isOnTime) {
//         dataset[day].OnTime += 1;
//       } else {
//         dataset[day].Late += 1;
//       }
//     } catch (error) {
//       console.error("Error processing entry:", entry, error);
//     }
//   });

//   const sortedData = weekdayOrder.map((day, index) => ({
//     id: index + 1,
//     day,
//     OnTime: dataset[day].OnTime,
//     Late: dataset[day].Late,
//   }));

//   console.log("Processed and sorted dataset:", sortedData);

//   return sortedData;
// };
export const processAttendanceData = (
  rawData,
  timeThresholdLocal = "10:00"
) => {
  if (!Array.isArray(rawData) || rawData.length === 0) {
    console.error("Invalid or empty rawData:", rawData);
    return [];
  }

  console.log("Processing rawData:", rawData);

  const weekdayOrder = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  const dataset = weekdayOrder.reduce((acc, day) => {
    acc[day] = { OnTime: 0, Late: 0 };
    return acc;
  }, {});

  // Convert `10:00 AM Local Time` to UTC for accurate comparison
  const localDate = new Date();
  localDate.setHours(...timeThresholdLocal.split(":").map(Number), 0, 0); // Set local time
  const thresholdUTC = localDate.getUTCHours() * 60 + localDate.getUTCMinutes(); // Convert to UTC minutes
  console.log("thresholdUTC", thresholdUTC);

  rawData.forEach((entry) => {
    if (!entry || !entry.checkInTime) {
      console.warn("Skipping invalid entry:", entry);
      return;
    }

    try {
      const date = new Date(entry.checkInTime); // `entry.checkInTime` is in UTC
      if (isNaN(date)) {
        console.warn("Invalid date:", entry.checkInTime);
        return;
      }

      const day = date.toLocaleString("en-US", { weekday: "short" }); // Get local weekday

      // Get check-in time in UTC minutes
      const checkInUTC = date.getUTCHours() * 60 + date.getUTCMinutes();

      const isOnTime = checkInUTC < thresholdUTC; // Compare UTC times

      if (isOnTime) {
        dataset[day].OnTime += 1;
      } else {
        dataset[day].Late += 1;
      }
    } catch (error) {
      console.error("Error processing entry:", entry, error);
    }
  });

  const sortedData = weekdayOrder.map((day, index) => ({
    id: index + 1,
    day,
    OnTime: dataset[day].OnTime,
    Late: dataset[day].Late,
  }));

  console.log("Processed and sorted dataset:", sortedData);

  return sortedData;
};
