import React, { useState, useEffect } from "react";
import { Box, Chip } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import dashBoardLayout from "../Assets/2DLayoutImage.jpeg";

import MovementTrackingWebSocket from "../Websockets/websocket";

const RoomLayout = () => {
  const [personLocations, setPersonLocations] = useState([]);
  const [isWebSocketConneted, setIsWebSocketConneted] = useState(true);

  // Define the fixed positions for each room
  const roomPositions = {
    DOOR: [
      { email: "hilmi@aitssg.com", top: "77%", left: "80%", name: "Hilmi" },
      { email: "rachel@aitssg.com", top: "70%", left: "80%", name: "Rachel" },
      { email: "millet@aitssg.com", top: "68%", left: "85%", name: "Millet" },
      { email: "johnson@aitssg.com", top: "75%", left: "85%", name: "Johnson" },
      { email: "skselva@aitssg.com", top: "64%", left: "85%", name: "Selva" },
    ],
    TV: [
      { email: "hilmi@aitssg.com", top: "45%", left: "46%", name: "Hilmi" },
      { email: "rachel@aitssg.com", top: "45%", left: "51%", name: "Rachel" },
      { email: "millet@aitssg.com", top: "33%", left: "50%", name: "Millet" },
      { email: "johnson@aitssg.com", top: "50%", left: "50%", name: "Johnson" },
      { email: "skselva@aitssg.com", top: "38%", left: "47%", name: "Selva" },
    ],
    POTTED_PLANT: [
      { email: "hilmi@aitssg.com", top: "43%", left: "13%", name: "Hilmi" },
      { email: "rachel@aitssg.com", top: "48%", left: "14%", name: "Rachel" },
      { email: "millet@aitssg.com", top: "36%", left: "12%", name: "Millet" },
      { email: "johnson@aitssg.com", top: "30%", left: "12%", name: "Johnson" },
      { email: "skselva@aitssg.com", top: "34%", left: "17%", name: "Selva" },
    ],
  };
  const personColors = {
    "hilmi@aitssg.com": "blue",
    "rachel@aitssg.com": "green",
    "millet@aitssg.com": "orange",
    "johnson@aitssg.com": "purple",
    "skselva@aitssg.com": "red",
  };

  let onWsMessageReceived = (message) => {
    if (message) {
      processWebSocketData(message);
    }
  };
  // const data = [
  //   {
  //     email: "hilmi@aitssg.com",
  //     name: "himi",
  //     checkInTime: "2025-01-28 00:31:52",
  //     location: "DOOR",
  //   },
  //   {
  //     email: "rachel@aitssg.com",
  //     name: "rachel",
  //     checkInTime: "2025-01-28 00:31:52",
  //     location: "DOOR",
  //   },
  //   {
  //     email: "johnson@aitssg.com",
  //     name: "johnson",
  //     checkInTime: "2025-01-28 00:31:52",
  //     location: "DOOR",
  //   },
  //   {
  //     email: "millet@aitssg.com",
  //     name: "milet",
  //     checkInTime: "2025-01-28 00:31:52",
  //     location: "DOOR",
  //   },
  //   {
  //     email: "skselva@aitssg.com",
  //     name: "skelva",
  //     checkInTime: "2025-01-28 00:31:52",
  //     location: "DOOR",
  //   },
  // ];

  // useEffect(() => {
  //   processWebSocketData(data);
  // }, []);
  const processWebSocketData = (data) => {
    const WebSocketData = Array.isArray(data) ? data : [data];

    setPersonLocations((prevLocations) => {
      const updatedLocations = WebSocketData?.map((personData) => {
        const room = personData?.location;
        const position = roomPositions[room]?.find(
          (person) => person.email === personData.email
        );

        return position
          ? { ...position, name: personData?.name, email: personData?.email }
          : null;
      }).filter((person) => person !== null);

      // Create a new map to store only the latest position of each user
      const latestPositions = new Map();

      // First, add existing users from prevLocations (excluding those in updatedLocations)
      prevLocations.forEach((person) => {
        if (
          !updatedLocations.some((updated) => updated.email === person.email)
        ) {
          latestPositions.set(person.email, person);
        }
      });

      // Then, add or update users with the latest positions
      updatedLocations.forEach((updatedPerson) => {
        latestPositions.set(updatedPerson.email, updatedPerson);
      });

      // Convert map back to an array
      return Array.from(latestPositions.values());
    });
  };

  return (
    <Box sx={{ position: "relative", width: "100%", height: "500px" }}>
      <img
        src={dashBoardLayout} // Adjust to your image path
        alt="Room Layout"
        style={{ width: "100%", height: "100%", position: "absolute" }}
      />
      {personLocations?.map((person, index) => (
        <Box
          key={index}
          sx={{
            position: "absolute",
            top: person.top,
            left: person.left,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Chip
            avatar={<PersonIcon sx={{ fill: "#FFFFFF" }} />}
            sx={{
              backgroundColor: personColors[person.email],
              color: "#FFFFFF",
              boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.4)",
            }}
            label={person.name}
          />
        </Box>
      ))}

      {isWebSocketConneted && (
        <MovementTrackingWebSocket onChange={onWsMessageReceived} />
      )}
    </Box>
  );
};

// Wrap the RoomLayout component in StompSessionProvider

export default RoomLayout;
