import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import DataServices from "../Services/DataServices";
import {
  Grid,
  Autocomplete,
  Typography,
  TextField,
  Toolbar,
} from "@mui/material";
import BackDropLoading from "../Components/Loading";
import AppText from "../Utils/AppText";
import { showToast } from "../Components/ToastBox";
import moment from "moment";

const MeetingRoomBookingHistory = () => {
  const [userList, setUserList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [orgDropDownOptions, setOrgDropDownOptions] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [initialPage, setInitialPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const fetchOrgDropdownData = async () => {
      try {
        setIsLoading(true);
        const response = await DataServices.getOrgDropDownDetailsApi();
        if (response.statusCode == 200) {
          try {
            var dummy = [...response.response.body];
            if (orgDropDownOptions < 1) {
              setOrgDropDownOptions(dummy);
              const defaultOption = dummy?.find(
                (option) => option?.value === "202501271"
              );
              setSelectedOrganization(defaultOption || dummy[0]);
            }
          } catch (error) {
            showToast("Something went wrong ", "error");
          }
        } else {
          showToast(response.statusDescription, "error");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrgDropdownData();
  }, []);

  useEffect(() => {
    if (selectedOrganization?.value?.length > 0) {
      meetingRoomBookingHistoryCall(initialPage, rowsPerPage);
    }
  }, [selectedOrganization]);

  const meetingRoomBookingHistoryCall = async (initialPage, rowsPerPage) => {
    try {
      setIsLoading(true);
      const queryParams = {
        orgCode: selectedOrganization?.value,
        pageNo: initialPage,
        pageSize: rowsPerPage,
      };

      const response = await DataServices.meetingRoomBookingHistory(
        queryParams
      );

      if (response?.statusCode === "200" && response?.response?.body) {
        const unsortedData = response.response.body;
        setUserList(unsortedData.content);
        setTotalRows(unsortedData.totalElements);
      } else {
        console.error("Unexpected response:", response);
      }
    } catch (error) {
      console.error("An error occurred while fetching data:", error);
    } finally {
      setIsLoading(false); // Ensure loading stops
    }
  };

  const columns = [
    {
      name: "id",
      label: "S/No.",
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
        setCellProps: () => ({
          style: { textAlign: "center" }, // Center-align cell content
        }),
        customBodyRender: (value, tableMeta, updateValue) => {
          return <span>{tableMeta.rowIndex + 1}</span>;
        },
      },
    },
    {
      name: "roomName",
      label: "Meeting Room Name",
      options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
        setCellProps: () => ({
          style: { textAlign: "center" }, // Center-align cell content
        }),
      },
    },
    {
      name: "startTime",
      label: "Start Date and Time",
      options: {
        sort: true,
        customBodyRender: (value) =>
          moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss"),

        setCellHeaderProps: (value) => ({
          align: "center",
        }),
        setCellProps: () => ({
          style: { textAlign: "center" }, // Center-align cell content
        }),
      },
    },
    {
      name: "endTime",
      label: "End Date and Time",
      options: {
        sort: true,
        customBodyRender: (value) =>
          moment.utc(value).local().format("YYYY-MM-DD HH:mm:ss"),
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
        setCellProps: () => ({
          style: { textAlign: "center" }, // Center-align cell content
        }),
      },
    },
    // {
    //   name: "bookingId",
    //   label: "Meeting Room Id",
    //   options: {
    //     sort: true,
    //     setCellHeaderProps: (value) => ({
    //       align: "center",
    //     }),
    //   },
    // },
    {
      name: "organizerEmail",
      label: "Organizer Email",
      options: {
        sort: true,
        setCellHeaderProps: (value) => ({
          align: "center",
        }),
        setCellProps: () => ({
          style: { textAlign: "center" }, // Center-align cell content
        }),
      },
    },

    {
      name: "invitees",
      label: "Invitees",
      options: {
        customBodyRender: (value) =>
          Array.isArray(value) ? value.join(", ") : "-",
      },

      sort: true,
      setCellHeaderProps: (value) => ({
        align: "center",
      }),
      setCellProps: () => ({
        style: { textAlign: "center" }, // Center-align cell content
      }),
    },
    {
      name: "attendees",
      label: "Attendees",
      options: {
        customBodyRender: (value) =>
          Array.isArray(value) ? value.join(", ") : "-",
      },

      sort: true,
      setCellHeaderProps: (value) => ({
        align: "center",
      }),
      setCellProps: () => ({
        style: { textAlign: "center" }, // Center-align cell content
      }),
    },
  ];

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    meetingRoomBookingHistoryCall(initialPage, newRowsPerPage);
  };
  const rowsPerPageOptions = [10, 25, 50, 100];
  const options = {
    responsive: "standard",
    filter: false,
    selectableRows: "none",
    search: false,
    serverSide: true,
    count: totalRows,
    download: false,
    print: false,
    viewColumns: false,
    pagination: true,
    page: initialPage,
    rowsPerPage: rowsPerPage,
    rowsPerPageOptions: rowsPerPageOptions,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setInitialPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setInitialPage(0);
          break;

        default:
      }
    },

    onChangeRowsPerPage: handleRowsPerPageChange,
  };

  return (
    <Grid>
      <Grid>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            {AppText.mRCmTxt.meetingRoomBookinHistory}
          </Typography>
        </Toolbar>
      </Grid>
      <Grid container spacing={0} direction="column">
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="OrganizationName"
            options={orgDropDownOptions}
            sx={{ width: 250, float: "left", marginBottom: "20px" }}
            value={selectedOrganization}
            onChange={(event, newValue) => setSelectedOrganization(newValue)}
            renderInput={(params) => (
              <TextField {...params} label="Organization Name" />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <MUIDataTable
            title={<div></div>}
            data={userList}
            columns={columns}
            options={options}
          />
        </Grid>
      </Grid>
      {isLoading && <BackDropLoading open={isLoading} />}
    </Grid>
  );
};

export default MeetingRoomBookingHistory;
